@import "../../styles/constants/colors";

.heading {
  display: flex;
  width: 100%;

  & > div {
    font-weight: bold;
    flex-grow: 0.5;
  }
}

.contentLine {
  position: relative;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $color-border-light;
  color: $color-primary-blue-dark;
  gap: 10px;
}

.backButton {
  height: 40px;

  // Make sure the button is given enough width
  flex-shrink: 0;
  border: solid $color-borders;
  border-width: 1px 1px 1px 0;
  background-color: $color-white;
  border-radius: 4px;
}

.productContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;

  & .productThumbnailContainer {
    width: 50px;
    margin-right: 10px;

    & .productThumbnail, & img {
      width: 3rem;
    }
  }

  & .productDataContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: $color-primary-blue-dark;
  }
}

.productSearchItem, .productListItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .productAction {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.existingCredits {
  background-color: $color-primary-blue-dark;
  color: $color-white;
  font-weight: bold;
  text-align: right;
  padding: 5px 10px;
  border-radius: 24px;
  width: 73px;
}

.creditsInput {
  width: 150px;
}

.bigCreditsInput {
  width: 180px;
}

.errorInput {
  & input {
    border-color: $color-red;
    position: relative;
  }
}

.errorText {
  color: $color-red;
  margin-top: 10px;
}

.errorGlyph {
  position: absolute;
  top: 35px;
  right: 48px;
  width: 24px;
  height: 24px;
  color: $color-red;
}

.noBorder {
  border: none;
}

.headerInsert {
  display: flex;
  justify-content: flex-end;

  button {
    height: 40px;
    flex-shrink: 0;
    border: 1px solid $color-borders;
    background-color: $color-white;
    border-radius: 4px;
    padding: 3px 10px;
    color: $color-primary-blue-dark;
    transform: translateY(-25px);
    line-height: 35px;
    cursor: pointer;
  }
}
