@import "../../styles/constants/colors";

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin-top: 2px;
  transform: translate(0, -18%);
}

.children {
  flex-grow: 1;
  align-items: center;
  padding-top: 12px;
  margin-bottom: 1px;
}

.icon {
  min-width: 30px;
  max-width: 30px;
  height: 45px;
  margin-top: calc(2em + 5px);
  margin-left: 10px;
}

.icon2 {
  position: absolute;
  top: 62px;
  right: 48px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
}



.status {
  position: relative;
  padding: 7px 15px;
  border-radius: 4px;
  margin: 5px 40px 0 0;
  background: $color-error;
  color: $color-white;

  &::before {
    position: absolute;
    top: -19px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: $color-weak;
    content: '';
  }

  &.statusArrowOnSecondField {
    &::before {
      left: 40%;
    }
  }

  &.statusWithoutArrow {
    &::before {
      border: 0;
    }
  }
}

.warning {
  background: $color-warning;

  &::before {
    border-bottom-color: $color-warning;
  }
}

.valid {
  background: $color-good;

  &::before {
    border-bottom-color: $color-good;
  }
}

.notice {
  background: $color-primary-blue;

  &::before {
    border-bottom-color: $color-primary-blue;
  }
}

.iconHidden {
  margin-right: 0;
}

.htmlNotice a {
  color: $color-white;
  text-decoration: underline;
}
