@use "sass:map";
@use "~@oup/shared-front-end/src/styles/themes/base/sizes";
@use "../../styles/constants/colors";
@use "~@oup/shared-front-end/src/styles/themes/base/typography";
@use "../../styles/constants/breakpoints";

.ClassProgress {
  &__header {
    display: flex;
  }

  &__title {
    flex-grow: 1;
    margin-top: 0.3em;
  }

  &__export_button {
    display: none;
  }

  @media #{breakpoints.$lgMedia} {
    &__export_button {
      display: inline-block;
    }
  }
}

.progressSettingsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: sizes.$gap-4 0;
  background-color: colors.$color-white;

  .unitTitle {
    color: map.get(colors.$primary, primary100);
    font-family: typography.$font-family-text-font;
    font-size: typography.$font-size-medium;
    font-weight: typography.$font-weight-700;

    @media #{breakpoints.$xsMedia} {
        display: none;
    }
  }
}

.progressContainerFixed {
  position: fixed;
  z-index: 2;

  @media #{breakpoints.$xsMedia} {
    right: 0;
    left: 0;
    width: auto !important;
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
}

body[data-theme="hub-theme"] {
  .ClassProgress {
    &__header {
      width: 100%;
      justify-content: space-between;
    }
  }
}
