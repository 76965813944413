.enrolUserReview {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2em;

  .recordsCount {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
  }

  .glyph {
    display: inline-block;
    width: 28px;
    vertical-align: middle;
  }
}
