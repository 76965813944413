.exampleFileStructure {
  margin-top: 2em;
  font-size: 12px;

  table {
    min-width: 350px;
    border: none;
    border-radius: 10px;
    border-collapse: separate;
    border-spacing: 0;
    box-shadow: 0 0 10px 0 #CCD3DA;
  }

  table td:nth-child(2) {
    color: unset;
    font-weight: unset;
  }

  table tr {
    border-color: none;
  }

  table tr th,
  table tr td {
    padding: 8px 16px;
    border-top: none;
    border-right: 1px solid #CCD3DA;
    border-bottom: 1px solid #CCD3DA;
    border-left: none;
  }

  table tr th:first-child,
  table tr td:first-child {
    border-left: 1px solid #CCD3DA;
  }

  table tr:first-child th {
    border-top: solid 1px #CCD3DA;
  }

  table tr th {
    background: #eee;
    text-align: center;
  }

  /* top-left border-radius */
  table tr:first-child th:first-child {
    border-top-left-radius: 6px;
  }

  /* top-right border-radius */
  table tr:first-child th:last-child {
    border-top-right-radius: 6px;
  }

  /* bottom-left border-radius */
  table tr:last-child th:first-child {
    border-bottom-left-radius: 6px;
  }

  /* bottom-right border-radius */
  table tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
  }

  .value {
    display: block;
    width: 100%;
    min-width: 50px;
    height: 1em;
    border-radius: 5px;
    background-color: #CCD3DA;
    opacity: .5;
  }

  .empty {
    position: relative;
  }

  .empty::after {
    position: absolute;
    left: -8px;
    width: 100%;
    height: 100%;
    padding: 4px 8px;
    border-radius: 4px;
    margin-top: -4px;
    box-shadow: 0 0 0 2px #D90429;
    content: '';
  }

  .note {
    display: flex;
    justify-content: flex-end;
    margin: 1em;
    text-align: right;
  }

  .optional {
    color: #008000;
  }
}
